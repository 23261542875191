import React, { useEffect, useState, useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import "./style.modules.css";
import RandomNumberForm from './RandomNumberForm';
import { apkPromiseFunction } from '../../../../utils/redirectCheck';
import PageContext from '../../../context/page/pageContext';
import { FbPixelEvent_Lead } from "../../../../utils/FbPixel/fbPixel";
import lsFourteenFbSmartScriptLib from "../../SmartScriptAppFlyer/lsFourteenFbSmartScriptLib"

const MobileNumberFormNotification = ({ apkPromise }) => {


  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [error, setError] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [mobileBlockActive, setMobileBlockActive] = useState(true);
  const [downloadNotificationModal, setDownloadNotificationModal] = useState(false);
  const { pageRelatedTo } = useContext(PageContext);

  function enableMobileBlock() {
    setMobileBlockActive(true);
  }

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\+?[0-9]*$/.test(value)) {
      setMobileNumber(value);
    }
  };

  useEffect(() => {

    const mobileNumberPattern = /^[6789]\d{9}$/;
    if (mobileNumberPattern.test(mobileNumber)) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [mobileNumber]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const mobileNumberPattern = /^[6789]\d{9}$/;

    if (!mobileNumberPattern.test(mobileNumber)) {
      setError('Please enter a valid 10-digit mobile number.');
      return;
    }

    setError('');
    setLoading(true);

    apkPromiseFunction(apkPromise, "_self");
    FbPixelEvent_Lead();
  
    try {
      const response = await fetch('https://4vb7qetg8g.execute-api.ap-south-1.amazonaws.com/v1/downloads', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': "application/json",
        },
        body: JSON.stringify({ "mobileNumber": mobileNumber, "pageFrom": pageRelatedTo.replace("page", "") })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // const result = await response.json();

      setTimeout(() => {
        setLoading(false);
        setDownloadNotificationModal(true);
      }, 2000);

    } catch (error) {
      setError('There was a problem while submitting your mobile number. Please try again after some time.');
      console.error('Error:', error);
      setLoading(false);
    }

  };

  const closeModal = () => {
    setModalVisible(false);
    location.reload();
  };
  const reloadBtnClick = () => {
    location.reload();
  }

  return (
    <>
      <div className={`contact-details-container ${modalVisible ? "modal-enabled" : ""}`}>
        <div className='contact-detail-header'>
          <StaticImage
            src='https://static-perf1.zupee.com/wp-content/uploads/2024/06/real-money-earning-app-zupee-logo.webp'
            alt='logo'
            width={140}
            height={51}
          />
        </div>
        {!mobileBlockActive ?
          <div className='first-block'>
            <StaticImage src="https://static-perf1.zupee.com/wp-content/uploads/2024/08/App-search.webp" alt="icon" className="icon" width={80} height={80} />
            <div className='first-block-heading'>Zupee App nahi mili?</div>
            <div className='first-block-subheading'>Hum aapko call pe help karenge</div>
            <div className='button-block'>
              <button className='primary-btn' onClick={enableMobileBlock}>Yes, Call Me</button>
              <button className='secondary-btn' onClick={reloadBtnClick}>Go to Home</button>
            </div>
          </div>
          :
          <div className={`mobile-no-block ${modalVisible ? "modal-enabled" : ""} ${!modalVisible && downloadNotificationModal ? 'download__yellow__section' : ''}`}>
            {!downloadNotificationModal ?
              <> <div className='inner__form__wrapper'><form onSubmit={handleSubmit}>
                <label htmlFor="mobileNumber" className='label'>Phone Number</label>
                <span className='subtext__from__inner__wrapper'>Enter your phone number to <span className='bold__make'>claim ₹10</span></span>
                <div className='main-content-box'>
                  <input
                    type="tel"
                    placeholder='Enter your Mobile Number'
                    id="mobileNumber"
                    value={mobileNumber}
                    onChange={handleChange}
                    autoFocus
                    maxLength={10}
                  />
                  {error && <p style={{ color: 'red' }}><small>{error}</small></p>}
                  <div className='button-block'>
                    <button className='primary-btn' type="submit" disabled={!isButtonEnabled || loading}>
                      {loading ?
                        <>
                          {/*<span className="loader__spinner"></span>*/}
                          <span>Submitting...</span>
                        </>
                        : "Submit"}
                    </button>
                  </div>
                </div>
              </form></div>
                <RandomNumberForm /></>
              : !modalVisible ?
              <div className='downloadwritten__wrapper'>
              <div className='yellow__check__avaliable'>
                <div className='check__wrapper__first'>
                  <div className='check__wrapper__image'>
                    <StaticImage
                      src='https://static-perf1.zupee.com/blog-images/uploads/2024/12/SA-graphical-icons.webp'
                      className="callback icon"
                      alt="check icon"
                      width={70}
                    /></div>
                  <div className='check__wrapper__paragraph'>
                    <b>₹10 credited Successfully</b><br /> to your Zupee wallet!
                  </div>
                </div>

                <div className='white__bg__downloading'>

                  <div className='first__section__downlaoding'>
                    Downloading Game App👆<br /><span className='insidecheckbbox'>Check notifications or <br /> my file/folder to find app</span>
                  </div>
                  <div className='downloading__image__bg__section'>
                    <StaticImage
                      src='https://static-perf1.zupee.com/blog-images/uploads/2024/12/Group-5222.webp'
                      alt="downloading image"
                      width={225}
                    />
                  </div>
                  <div className='bottom__download__aginan'>
                    <span className='security__check'>
                      <StaticImage
                        src='https://static-perf1.zupee.com/blog-images/uploads/2024/12/SA-UI-icons.webp'
                        alt="security check"
                        width={16}
                      /> 100% Safe & Secure
                    </span>
                    <span className='download__again__link__section'>
                      Download didn’t start? <a href={process.env.DEFAULT_APK_LINK}>Restart the Download</a>
                    </span>
                  </div>

                </div>
              </div>
            </div>
                :
          <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={reloadBtnClick}>&times;</span>
            <StaticImage
              src="https://static-perf1.zupee.com/wp-content/uploads/2024/08/Call-back.webp"
              alt="callback icon"
              className="callback icon"
              width={80}
              height={80}
            />
            <p className='callback-text'>Hamari team aapko call pe help karegi</p>
            <button className='primary-btn' onClick={reloadBtnClick}>Okay</button>
          </div>
          </div>
            }
          </div>
        }
      </div>
    </>
  );
};

export default MobileNumberFormNotification;
